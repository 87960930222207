<script>
import webappRoutes from '@/data/enums/webappRoutes'

export default {
  props: {
    type: {
      type: String,
      default: 'post',
    },
    alias: {
      type: String,
      default: '',
    },
    destination: {
      type: String,
      default: process.env.VUE_APP_WEBAPP_PROD_URL,
    },
    devDestination: {
      type: String,
      default: process.env.VUE_APP_WEBAPP_DEV_URL,
    },
  },
  data() {
    return {
      webappRoutes
    }
  },
  computed: {
    url() {
      return `${process.env.NODE_ENV === 'production' ? this.destination : this.devDestination}/${this.webappRoutes[this.type]}/${this.alias}?preview=1`
    }
  }
};
</script>

<template>
  <a 
    class="previewLink"
    :href="url"
    target="_blank"
  >
    <slot />
  </a>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>

