<script>
import postsService from '@/services/posts.service'
import consolesService from '@/services/consoles.service'
import libraryService from '@/services/library.service'
import Post from '@/data/models/Post'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'
import Datetime from 'vuejs-datetimepicker'
import PreviewLink from '@/components/PreviewLink'
import formTypes from '@/data/arrays/formTypes.array'

export default {
  name: 'SinglePost',
  components: {
    EditLayout,
    Datetime,
    PreviewLink,
  },
  beforeRouteLeave(to, from, next) {
    if(this.hasChanges) {
      this.$notify({
        message: this.$t('unsavedChanges'),
        icon: "far fa-bell",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      })
    }else {
      next()
    }
  },
	data() {
		return {
      item: new Post,
      photo: null,
      consoles: [],
      indexing: indexing,
      isLoaded: false,
      hasChanges: false,
      publishDate: null,
      datePickerIsLoaded: 0,
      formTypes: formTypes,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
    users() {
      return this.$store.state.users.users
    },
    categories() {
      return this.$store.state.posts.categories || []
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.hasChanges = this.isLoaded
        this.hasChanges && this._preventWindowClose()
        this.isLoaded = true
      }
    },
    publishDate: {
      handler() {
        if(this.datePickerIsLoaded > 1 || this.isNew) {
          const localeDate = this.publishDate
          const jsonDate = new Date(localeDate).toJSON()
          this.item.published_at = jsonDate
          if(this.publishDate) this.item.published = 1
        }
        this.datePickerIsLoaded ++
      }
    }
  },
	created() {
    this._getConsoles()
    !this.isNew && this.id && this._get()
    if(this.isNew) this.item.user_id = this.$store.state.auth.authUser.id
	},
	methods: {
    _get() {
      postsService.getById({ id: this.id })
      .then(res => {
        this.item = res.data

        if(this.item.published_at) {
          const inputDate = new Date(this.item.published_at).toUTCString()
          const date = new Date(inputDate)
          const seconds = date.getSeconds()
          const minutes = date.getMinutes()
          const hour = date.getHours()
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()
          this.publishDate = `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`
        }else{
          this.datePickerIsLoaded = 2
        }
        res.data.library_id && this._getPhoto({ id: res.data.library_id })
      })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    _getConsoles() {
      consolesService.getAll({})
        .then(res => {
          this.consoles = res.data
        })
    },
    create() {
      postsService.create({ data: this.item })
        .then(response => {
          this.hasChanges = false;
          this.datePickerIsLoaded = 0;
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('post') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'posts' })
        })
    },
    edit() {
      postsService.edit({ data: this.item })
        .then(_res => {
          this.hasChanges = false;
          this.datePickerIsLoaded = 2;
          window.onbeforeunload = null;
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('post') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    updateSelectedConsoles(newVal) {
      this.item.related_consoles = newVal
    },
    updateSelectedCategories(newVal) {
      this.item.category = newVal
    },
    _preventWindowClose() {
      window.onbeforeunload = this._confirmMessage
    },
    _confirmMessage() {
      return this.$t('unsavedChangesQuestion')
    },
  }
}
</script>

<template>
  <EditLayout 
    supports-photo
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="subtitle">
      <BaseInput
        v-model="item.subtitle"
        :placeholder="`${$t('enter')} ${$t('subtitle')} ${$t('here')}...`"
      />
    </div>
    <div slot="sidebar-middle">
      <PreviewLink
        v-if="!isNew && item"
        :alias="item.alias"
      >
        <BaseButton>{{ $t('preview') }}</BaseButton>
      </PreviewLink>
      <div class="datePicker">
        <label>{{ $t('publishDateAndTime') }}</label>
        <Datetime
          v-model="publishDate"
          first-day-of-week="1"
          format="YYYY/MM/DD H:i:s"
        />
      </div>
      <div>
        <label>{{ $tc('author') }}</label>
        <select
          v-model="item.user_id"
          class="form-control"
        >
          <option
            v-for="user in users"
            :key="user.id"
            :value="user.id"
          >
            {{ user.username }}
          </option>
        </select>
      </div>
      <div>
        <BaseMultiSelect
          :label="$tc('category', 2)"
          :items="categories"
          :pre-selected-items="item.category"
          @updateSelected="updateSelectedCategories"
        />
      </div>
      <div>
        <BaseMultiSelect
          :label="$t('relativeConsoles')"
          :items="consoles"
          :pre-selected-items="item.related_consoles"
          @updateSelected="updateSelectedConsoles"
        />
      </div>
      <div class="row">
        <div class="col-6">
          <BaseInput
            :label="$t('gamesRelated')"
            is-slider-checkbox
          >
            <input
              v-model="item.game_related"
              class="form-control"
              type="checkbox"
              true-value="1"
              false-value="0"
            >
          </BaseInput>
        </div>
        <div class="col-6">
          <BaseInput
            :label="$t('pcRelated')"
            is-slider-checkbox
          >
            <input
              v-model="item.pc_related"
              class="form-control"
              type="checkbox"
              true-value="1"
              false-value="0"
            >
          </BaseInput>
        </div>
      </div>
    </div>
    <div slot="sidebar-bottom">
      <div class="formType form-group">
        <label>{{ $t('formType') }}</label>
        <select
          v-model="item.form_type"
          class="form-control"
        >
          <option :value="null">
            {{ $t('none') }}
          </option>
          <option
            v-for="formType in formTypes"
            :key="formType"
            :value="formType"
          >
            {{ formType }}
          </option>
        </select>
      </div>
      <div class="metaIndexling form-group">
        <label>{{ $t('metaIndexing') }}</label>
        <select
          v-model="item.seo_indexing"
          class="form-control"
        >
          <option :value="null">
            {{ $t('indexing') }}
          </option>
          <option
            v-for="index in indexing"
            :key="index"
            :value="index"
          >
            {{ index }}
          </option>
        </select>
      </div>
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.datePicker 
  margin-bottom: $base-spacing
</style>
