/* eslint-disable camelcase */

export default class Post {
  name;
  description;
  description_socials;
  library_id;
  user_id;
  alias;
  game_related;
  pc_related;
  published;
  published_at;
  type;
  seo_indexing;
  seo_title;
  subtitle;
  related_consoles;
  form_type;

  constructor() {
    this.name = '';
    this.description = '';
    this.description_socials = '';
    this.library_id = null;
    this.user_id = null;
    this.alias = '';
    this.game_related = "0";
    this.pc_related = "0";
    this.published = 0;
    this.published_at = null;
    this.type = 'post';
    this.seo_indexing = null;
    this.seo_title = '';
    this.subtitle = '';
    this.related_consoles = [];
    this.form_type = null;
  }
}
